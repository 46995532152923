/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// Custom Component
import ApiErrorMsg from "_components/ApiErrorMsg";
import InnerLoader from "_components/InnerLoader";

// Lodash
import { isEqual } from "lodash";

//utils
import {
  Button, Chip, FormControlLabel, InputLabel, MenuItem, Select, Switch, FormControl
} from "@mui/material";
import TypeTagFilter from "_components/TypeTagFilter";
import {
  formattedErrorResponse,
  generateUpdateApiQueryParams,
  uniqueMetaDataColumns
} from "_utils/commonFunctions";
import getCustomTableMetaDataById from "_utils/customTableMetaData";
import { getNestedTableFormattedData } from "_utils/tableDataFormatted";
const AdvanceTable = React.lazy(() => import("_components/AdvanceTable"));
// old state holder
let tableOldState = {};

const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const onScroll = (event) => setScrollTop(event.target.scrollTop);
  return [scrollTop, { onScroll }];
};

const defaultOptionMapFun = (data) => {
  return { label: data.name ? data.name : data.referenceId ?? data.id, value: data.id, ...(data?.url) && { imageUrl: data?.url, url: data?.url } }
}

const OptionTable = ({
  onConfirm,
  defaultSelected,
  open,
  handleClose,
  name,
  getMetaData,
  getRowData,
  selectType,
  isTypeSelect,
  getsTypesRowData,
  getTagById,
  dataList,
  types,
  paramTypeIdName,
  maxLimit,
  staticValues,
  selectedListLabelMap = defaultOptionMapFun,
  customParam,
  isActionHide,
  hideConfirm,
  filterType,
  showVendorFeedToggle = false,
  vendorFeedOptions = {},
  ...rest
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const defaultTypeId = rest?.defaultSelectedTypeId || location?.state?.type
  const [metaData, setMetaData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [errorLoading, seErrorLoading] = useState(null);
  const [advanceTableData, setAdvanceTableData] = useState(null);
  const refs = useRef(true);
  const [loading, setLoading] = useState(true);
  const advancedTable = useRef();
  const [actionId, setActionId] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [componentType, setComponentType] = useState(
    defaultTypeId ?? null
  );
  const [tagOptions, setTagOptions] = useState();
  const [selectedTag, setSelectedTag] = useState({});
  const [viewAllTags, setViewAllTags] = useState(false);
  const [scrollTop, scrollProps] = useScrollTop();
  const [imageUrls, setImageUrls] = useState([]);
  const [vendorFeed, setVendorFeed] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');

  const handleClick = (type, rowData, event) => {
    const props = rowData.original;
    if (type === "select") {
      if (
        (event.target.checked && !maxLimit) ||
        (event.target.checked && maxLimit && optionList.length < maxLimit)
      ) {
        const option = optionList.filter((op) => op.value === props.id);
        if (!option?.[0]) {
          setOptionList((prev) => [
            selectedListLabelMap({ ...props, rawData: rowData?.rawData }),
            ...(selectType === "multiSelect" ? prev : []),
          ]);
        }
        if (selectType === "multiSelect") {
          setActionId((prev) => [props.id, ...prev]);
        } else {
          setActionId([props.id]);
          setImageUrls([props?.url]);
        }
      } else {
        setActionId((prev) => prev.filter((id) => id !== props.id));
        setOptionList((prev) => prev.filter((l) => l.value !== props.id));
      }
    }
  };

  useEffect(() => {
    if (showVendorFeedToggle && typeof vendorFeedOptions.getDropdownOption === 'function') {
      dispatch(vendorFeedOptions.getDropdownOption()).then((response) => {
        const vendors = response.payload?.data || [];
        if (vendors.length > 0) {
          setVendorList(vendors);
          setSelectedVendor(vendors[0]?.id);
        }
      });
    }
  }, [showVendorFeedToggle, vendorFeedOptions.getDropdownOption]);

  useEffect(() => {
    if (open && defaultSelected) {
      if (selectType === "multiSelect") {
        setActionId(defaultSelected);
      } else {
        setActionId([defaultSelected]);
      }
      setOptionList(dataList ?? []);
    }
  }, [open, defaultSelected, dataList]);

  const tablePropertyActionProps = [
    {
      actionType: "select",
      actionHandler: (props, event) =>
        handleClick("select", props?.cell?.row, event),
    },
  ];

  /* Load Meta Data on load */
  useEffect(() => {
    const firstRender = refs.current;
    if (firstRender) {
      refs.current = false;
      if (isTypeSelect) {
        loadTypes();
      } else {
        loadTableMetaData();
      }
    }
  });

  // only trigger update table api
  const stateHandler = async (tableState, reload = false) => {
    const newTableState = JSON.parse(JSON.stringify(tableState));
    if (!isEqual(tableOldState, newTableState)) {
      setLoading(true);
      tableOldState = newTableState;
      let queryParamsData = generateUpdateApiQueryParams({
        ...newTableState,
        [paramTypeIdName]: componentType,
      });
      loadTableRowData(queryParamsData);
    }
  };
  /* Load Meta Data */
  const loadTableMetaData = (id, tags = []) => {
    let queryParamsData = "";
    if (isTypeSelect) {
      queryParamsData = generateUpdateApiQueryParams({ [paramTypeIdName]: id });
    }
    if (staticValues) {
      setMetaData(getMetaData());
      let rawRowData = {}
      const data = getRowData();
      rawRowData.data = data
      rawRowData.pageCount = data?.length < 11 ? 1 : data?.length / 10;
      rawRowData.pageIndex = 0;
      rawRowData.pageSize = 10;
      rawRowData.filters = [];
      rawRowData.sortBy = [];
      setRowData(rawRowData);
      setDataLoading(false);
      setShowTable(true);
    } else {
      dispatch(getMetaData(queryParamsData))
        .then((response) => {
          if (response?.payload) {
            response.payload.columns = [...(response?.payload?.columns || []), ...(rest.customMetaDataMappingIds ? rest.customMetaDataMappingIds.map((id) => getCustomTableMetaDataById(id)).flat() : [])];
            if (isTypeSelect) {
              let queryParamsDataLoadTable;
              if (filterType === "inventoryTagPricing") {
                queryParamsDataLoadTable = generateUpdateApiQueryParams({
                  pageSize: 10,
                  pageIndex: 0,
                  inventoryType: 'stone',
                  searchKey: {
                    relations: [{
                      ...(vendorFeed ? {
                        'inventTagObj.vendorId': selectedVendor,
                      } : {
                        'inventoryTrayObj.locationId': id,
                      }),
                      'inventTagObj.isVendor': vendorFeed,
                    }],
                  },
                  ...(rest.customQueryParams ? rest.customQueryParams : {}),
                });
              } else {
                queryParamsDataLoadTable = generateUpdateApiQueryParams({
                  [paramTypeIdName]: id,
                  pageSize: 10,
                  pageIndex: 0,
                  ...(rest.customQueryParams ? rest.customQueryParams : {})
                });
              }
              if (tags.length > 0) {
                queryParamsDataLoadTable +=
                  '&tagIds={"id":["' + tags.join('","') + '"]}';
              }
              setMetaData(uniqueMetaDataColumns(response?.payload));
              loadTableRowData(queryParamsDataLoadTable);
            } else if (customParam) {
              let queryParamsDataLoadTable = generateUpdateApiQueryParams({
                ...(paramTypeIdName ? { navigationId: paramTypeIdName } : {}),
                pageSize: 10,
                pageIndex: 0,
                ...(rest?.referenceId ? { "searchKey[relations][][collection.referenceId]": rest?.referenceId } : {}),
                ...(rest.customQueryParams ? rest.customQueryParams : {})
              });
              setMetaData(uniqueMetaDataColumns(response?.payload));
              loadTableRowData(queryParamsDataLoadTable);
            } else {
              let queryParamsDataLoadTable = generateUpdateApiQueryParams({
                pageSize: 10,
                pageIndex: 0,
                ...(rest.customQueryParams ? rest.customQueryParams : {})
              });
              setMetaData(uniqueMetaDataColumns(response?.payload));
              loadTableRowData(queryParamsDataLoadTable);
            }
          } else {
            seErrorLoading(formattedErrorResponse(response?.error));
            setDataLoading(false);
          }
        })
        .catch((error) => {
          setDataLoading(false);
        });
    }
  };
  /* Load Row Data */
  const loadTableRowData = (params) => {
    seErrorLoading(null);
    dispatch(getRowData(params)).then((response) => {
      if (response?.payload) {
        let rawRowData = response?.payload;
        rawRowData.pageCount = rawRowData?.pageCount ? rawRowData.pageCount : 1;
        rawRowData.pageIndex = rawRowData?.pageIndex ? rawRowData.pageIndex : 0;
        rawRowData.pageSize =
          rawRowData?.pageSize !== 0 ? rawRowData.pageSize : 10;
        rawRowData.filters =
          (rawRowData?.filters !== 0 ? rawRowData.filters : []) || [];
        rawRowData.sortBy =
          (rawRowData?.sortBy !== 0 ? rawRowData.sortBy : []) || [];
        setRowData(rawRowData);
      } else {
        setRowData([]);
        setAdvanceTableData({
          ...rowData,
          data: [],
        });
        seErrorLoading(formattedErrorResponse(response?.error));
        setDataLoading(false);
      }
    });
  };

  // create new Modal
  const loadTypes = () => {
    if (types) {
      setTypeOptions(types);
      setComponentType(defaultTypeId ?? types[0]?.id);
      return;
    }
    dispatch(getsTypesRowData()).then((response) => {
      if (response?.payload?.data?.length > 0) {
        let typeOptionsRaw = [];
        response?.payload?.data?.forEach((item) => {
          typeOptionsRaw.push({
            id: item.id,
            label: item.name || item.id,
          });
        });
        setTypeOptions(typeOptionsRaw);
        setComponentType(defaultTypeId ?? typeOptionsRaw[0]?.id);
      } else if (response?.payload?.length > 0) {
        let typeOptionsRaw = [];
        response?.payload?.forEach((item) => {
          typeOptionsRaw.push({
            id: item,
            label: item,
          });
        });
        setTypeOptions(typeOptionsRaw);
        setComponentType(defaultTypeId ?? typeOptionsRaw[0]?.id);
      } else {
        setLoading(false);
        setDataLoading(false);
      }
    });
  };

  // load metadata by component types...
  useEffect(() => {
    if (typeof getTagById !== "function" && componentType) {
      loadTableMetaData(componentType);
    } else if (typeOptions?.length > 0 || componentType) {
      loadTableMetaData(componentType);
      dispatch(getTagById(componentType)).then((response) => {
        const componentTagOptionsRaw = [];
        response?.payload?.tags?.map((item) => {
          componentTagOptionsRaw.push({
            id: item?.id,
            label: item?.tagName,
            tagValues: item?.tagValues?.map((tagItem) => {
              return {
                id: tagItem.id,
                tagValue: tagItem.tagValue,
              };
            }),
          });
          return item;
        });
        setTagOptions(componentTagOptionsRaw);
        setSelectedTag({});
      });
    }
  }, [typeOptions, componentType, dispatch, vendorFeed, selectedVendor]);

  // Load data
  useEffect(() => {
    if (Array.isArray(metaData?.columns) && Array.isArray(rowData?.data)) {
      loadData(rowData, metaData);
    }
  }, [metaData, rowData]);
  // pass the query params as value in arguments
  const loadData = (rowData, metaData) => {
    const data = getNestedTableFormattedData(rowData?.data, metaData?.columns);
    setLoading(false);
    setAdvanceTableData({
      ...rowData,
      data,
    });
  };

  // finish Loading and table state updates
  useEffect(() => {
    if (
      Array.isArray(metaData?.columns) &&
      Array.isArray(advanceTableData?.data) &&
      dataLoading
    ) {
      setDataLoading(false);
      setShowTable(true);
      setLoading(false);
      if (advancedTable?.current) {
        advancedTable.current.fireFetchData();
      }
    }
  }, [advanceTableData, dataLoading, metaData?.columns]);

  const changeType = (typeValue) => {
    setComponentType(typeValue);
    if (rest?.onTypeChange) rest?.onTypeChange(typeValue);
    setViewAllTags(false);
    setLoading(true);
  };

  const clearSelectionTags = (tagId, value) => {
    delete value[tagId];
    setSelectedTag({ ...value });
  };

  const onFormSubmit = (values) => {
    setSelectedTag(values);
    const tags = [...Object.values(values)]?.flat();
    loadTableMetaData(componentType, tags);
  };
  const handleDelete = (removeId) => {
    setOptionList((prev) => prev.filter((l) => l.value !== removeId));
    setActionId((prev) => prev.filter((id) => id !== removeId));
  };

  const resetTableState = () => {
    setShowTable(false);
    setDataLoading(true);
    setLoading(true);
    setRowData(null);
    setMetaData(null);
    setAdvanceTableData(null);
    setOptionList([]);
  }
  return (
    <div className="select-table-container" {...scrollProps}>
      <div
        className="select-table-header"
        style={{
          boxShadow:
            scrollTop > 0 ? "rgba(0, 0, 0, 0.4) 1px 6px 5px -5px" : "none",
          transition: "box-shadow 0.3s",
        }}
      >
        <h2>{name}</h2>
        {isTypeSelect ? (
          <TypeTagFilter
            tagOptions={tagOptions}
            typeOptions={typeOptions}
            type={componentType}
            setType={changeType}
            clearSelectionTags={clearSelectionTags}
            setSelectedTag={setSelectedTag}
            selectedTag={selectedTag}
            onFormSubmit={onFormSubmit}
            viewAllTags={viewAllTags}
            setViewAllTags={setViewAllTags}
            label={filterType === "inventoryTagPricing" ? " Location" : `${name} Type`}
            typeLabel={rest.typeLabel ?? null}
            disabled={vendorFeed}
          >
            {showVendorFeedToggle ? <>
              <div className="col-sm-2">
                <FormControlLabel
                  control={
                    <Switch
                      checked={vendorFeed}
                      onChange={(e) => {
                        setVendorFeed(e.target.checked);
                        setViewAllTags(false);
                        setLoading(true);
                      }}
                    />
                  }
                  label="Vendor Feed"
                />
              </div>
              {vendorFeed && <div className="col-sm-3">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="vendor-label">Select {vendorFeedOptions.dropdownName}</InputLabel>
                  <Select
                    labelId="vendor-label"
                    id="vendor-select"
                    value={selectedVendor}
                    onChange={(e) => {
                      setSelectedVendor(e.target.value)
                    }}
                    label={`Select ${vendorFeedOptions.dropdownName}`}
                  >
                    {Array.isArray(vendorList) &&
                      vendorList?.map(({ id, name }, index) => (
                        <MenuItem key={index} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>}
            </> : null}
          </TypeTagFilter>
        ) : null}
      </div>
      {dataLoading || !advanceTableData ? <InnerLoader /> : null}
      {advanceTableData && showTable ? (
        <Suspense fallback={<InnerLoader />}>
          <AdvanceTable
            data={advanceTableData}
            metaData={metaData}
            loading={loading}
            actionProps={!isActionHide && tablePropertyActionProps}
            stateHandler={(param) => stateHandler(param)}
            onFetchData={() => {
              // console.log('fetchData')
            }}
            selectedValues={actionId}
            isDisabledState={true}
          />
        </Suspense>
      ) : null}

      {errorLoading && <ApiErrorMsg error={errorLoading?.error} />}

      {!dataLoading && (
        <div className="select-table-footer row">
          <div className="col-auto selected-tags-clm">
            {actionId.length > 0 ? (
              <div className="selected-items">
                {optionList?.map((data, index) =>
                  actionId.includes(data.value) ? (
                    <Chip
                      key={data.value}
                      className="m-1"
                      label={data.label}
                      disabled={errorLoading}
                      variant="outlined"
                      onDelete={() => handleDelete(data.value)}
                    />
                  ) : null
                )}
              </div>
            ) : null}
          </div>
          <div className="col select-table-footer-btn-clm">
            <Button
              type="submit"
              variant="outlined"
              size="large"
              onClick={() => { handleClose(); resetTableState() }}
            >
              Close
            </Button>
            {!staticValues && <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={errorLoading || loading || dataLoading}
              onClick={() => onConfirm(actionId, optionList, imageUrls)}
            >
              Confirm
            </Button>}
          </div>
        </div>
      )}
    </div>
  );
};
export default OptionTable;
