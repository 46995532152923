import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "orders";

// APIs
//Orders Data
/* table Meta Data */
export const getOrdersMetaData = createAsyncThunk(
  `${sliceName}/getOrdersMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order/getMetadata`)
);
export const getOrderRowData = createAsyncThunk(
  `${sliceName}/getOrderRowData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order/getAll?where=&pageSize=10&pageIndex=1&sortBy&searchKey&tagIds`)
);
export const getOrderVersionRowData = createAsyncThunk(
  `${sliceName}/getOrderVersionRowData`,
  async ({ id }) =>
    await fetchWrapper.get(`${baseUrl}/order/version-history/get/${id}`)
);
export const getOrderMetaData = createAsyncThunk(
  `${sliceName}/getOrderMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order/getMetadata`)
);
export const getOrdersRowData = createAsyncThunk(
  `${sliceName}/getOrderseMetaData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/order/getAll?${params || ""}`)
);
//order form
export const getOrderById = createAsyncThunk(
  `${sliceName}/getOrderById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order/get/${id}`)
);

export const getOrderDetailsById = createAsyncThunk(
  `${sliceName}/getOrderDetailsById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order/order-get/${id}`)
);

export const deleteOrder = createAsyncThunk(
  `${sliceName}/deleteOrder`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/order/delete/${id}`, { id })
);
export const editOrder = createAsyncThunk(
  `${sliceName}/editOrder`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order/update/${values.id}`,
      values
    );
    return res || res === "" ? res : null;
  }
);
export const addOrder = createAsyncThunk(
  `${sliceName}/addOrder`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/order/add`, values);
    return res || res === "" ? res : null;
  }
);

export const getOrderAllLocation = createAsyncThunk(
  `${sliceName}/getOrderAllLocation`,
  async () => await fetchWrapper.get(`${baseUrl}/location/get?filter=`)
);

export const getAllLocations = createAsyncThunk(
  `${sliceName}/getAllLocations`,
  async (params) => {
    if (params) {
      return await fetchWrapper.get(`${baseUrl}/location/dropdown/getAll?${params}`)
    } else {
      return await fetchWrapper.get(`${baseUrl}/location/dropdown/getAll`)
    }
  }
);

export const getOrderAllStore = createAsyncThunk(
  `${sliceName}/getOrderAllStore`,
  async () => await fetchWrapper.get(`${baseUrl}/store/getAll?where=&pageSize=10&pageIndex=0`)
);
export const getOrderAllCustomer = createAsyncThunk(
  `${sliceName}/getOrderAllCustomer`,
  async (id) => await fetchWrapper.get(`${baseUrl}/customer/getAll?id=${id}`)
);
export const getOrdersAllTags = createAsyncThunk(
  `${sliceName}/getOrdersAllTags`,
  async (params) => await fetchWrapper.get(`${baseUrl}/order-customer-states/getAll?filter=`)
);
//Orders Customer State Data
//Orders External State Data
/* table Meta Data */
export const getOrdersExternalStateMetaData = createAsyncThunk(
  `${sliceName}/getOrdersExternalStateMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order-external-states/getMetadata`)
);

export const getOrdersExternalStateRowData = createAsyncThunk(
  `${sliceName}/getOrdersExternalStateMetaData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/order-external-states/getAll?${params || ""}`)
);

export const deleteOrderExternalState = createAsyncThunk(
  `${sliceName}/deleteOrderExternalState`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/order-external-states/delete/${id}`, { id })
);

export const getOrderExternalStateById = createAsyncThunk(
  `${sliceName}/getOrderExternalStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order-external-states/get/${id}`)
);

export const addOrderExternalStates = createAsyncThunk(
  `${sliceName}/addOrderExternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/order-external-states/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editOrderExternalStates = createAsyncThunk(
  `${sliceName}/editOrderExternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-external-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// payments 

export const getCurrencies = createAsyncThunk(
  `${sliceName}/getCurrencies`,
  async () => {
    const res = await fetchWrapper.get(
      `${baseUrl}/currencies/dropdown/getAll`
    );
    return res;
  }
);

export const paymentMethods = createAsyncThunk(
  `${sliceName}/paymentMethods`,
  async (locationId) => {
    const res = await fetchWrapper.get(
      `${baseUrl}/location/${locationId}/payment-method/getAll`
    );
    return res;
  }
);

export const getOfflinePaymentMethods = createAsyncThunk(
  `${sliceName}/getOfflinePaymentMethods`,
  async (locationId) => {
    const res = await fetchWrapper.get(
      `${baseUrl}/store/${locationId}/payment-method/offline/getAll`
    );
    return res;
  }
)

export const addPaymentDetails = createAsyncThunk(
  `${sliceName}/addPaymentDetails`,
  async ({ values, orderId }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order/${orderId}/payment/add`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const updatePaymentDetails = createAsyncThunk(
  `${sliceName}/updatePaymentDetails`,
  async ({ values, orderId }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order/${orderId}/payment/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// shipping ...

export const getOrderPaymentDetails = createAsyncThunk(
  `${sliceName}/getOrderPaymentDetails`,
  async (orderId) =>
    await fetchWrapper.get(`${baseUrl}/order/${orderId}/payment/getAll`)
);

export const getOrderPaymentDetailsRowData = createAsyncThunk(
  `${sliceName}/getOrderPaymentDetailsRowData`,
  async ({ params, orderId }) =>
    await fetchWrapper.get(`${baseUrl}/order/${orderId}/payment/getAll?${params || ""}`)
);

export const getOrderPaymentDetailsMetaData = createAsyncThunk(
  `${sliceName}/getOrderPaymentDetailsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order/payment/getMetadata`)
);

export const addOrderShippingAddress = createAsyncThunk(
  `${sliceName}/addOrderShippingAddress`,
  async (body) => await fetchWrapper.post(`${baseUrl}/order/shipping-address/add`, body)
);

export const addShippingInstorePickup = createAsyncThunk(
  `${sliceName}/addShippingInstorePickup`,
  async (body) => await fetchWrapper.post(`${baseUrl}/order/shipping-instore-pickup/add`, body)
);

export const checkoutSubmitOrder = createAsyncThunk(
  `${sliceName}/checkoutSubmitOrder`,
  async (orderId) => await fetchWrapper.post(`${baseUrl}/order/${orderId}/checkout/submit`)
);

export const addOrderShippingMethod = createAsyncThunk(
  `${sliceName}/addOrderShippingMethod`,
  async (body) => await fetchWrapper.post(`${baseUrl}/order/shipping-method/add`, body)
);


export const getOrderShipping = createAsyncThunk(
  `${sliceName}/getOrderShipping`,
  async (orderId) =>
    await fetchWrapper.get(`${baseUrl}/order/${orderId}/shipping/getAll`)
);

export const getInstoreMethod = createAsyncThunk(
  `${sliceName}/getInstoreMethod`,
  async (storeId) =>
    await fetchWrapper.get(`${baseUrl}/store/${storeId}/instore-pickup/getAll`)
);
export const getStoreShippingMethod = createAsyncThunk(
  `${sliceName}/getStoreShippingMethod`,
  async (storeId) =>
    await fetchWrapper.get(`${baseUrl}/store/${storeId}/shipping-method/getAll`)
);

export const getShippingDetailsRowData = createAsyncThunk(
  `${sliceName}/getShippingDetailsRowData`,
  async ({ params, orderId }) =>
    await fetchWrapper.get(`${baseUrl}/order/${orderId}/shipping/getAll?${params || ""}`)
);

export const getShippingDetailsMetaData = createAsyncThunk(
  `${sliceName}/getShippingDetailsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order/shipping/getMetadata`)
);

export const getCustomerAllAddress = createAsyncThunk(
  `${sliceName}/getCustomerAllAddress`,
  async (customerId) => await fetchWrapper.get(`${baseUrl}/customer/${customerId}/address/getAll`)
);
export const editAddress = createAsyncThunk(
  `${sliceName}/editAddress`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/customer/${values.customerId}/address/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);
export const getCountryData = createAsyncThunk(
  `${sliceName}/getCountryData`,
  async (values) =>
    await fetchWrapper.get(`${baseUrl}/country/getAll?filter=`)
);
export const addAddress = createAsyncThunk(
  `${sliceName}/addAddress`,
  async ({ formData, customerId }) => {
    const res = await fetchWrapper.post(`${baseUrl}/customer/${customerId}/address/add`, formData);
    return res || res === "" ? res : null;
  }
);
export const getAddressById = createAsyncThunk(
  `${sliceName}/getAddressById`,
  async (customerId, id) => await fetchWrapper.get(`${baseUrl}/customer/${customerId}/address/update/${id}`)
);

//order line items
export const getOrderLineItemMetaData = createAsyncThunk(
  `${sliceName}/getOrderLineItemMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order-line-item/getMetadata`)
);

export const getAllOrderLineItemRowData = createAsyncThunk(
  `${sliceName}/getAllOrderLineItemRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/order/order-line-item/getAll?${params || ""}`)
);

export const getOrderLineItemRowData = createAsyncThunk(
  `${sliceName}/getOrderLineItemRowData`,
  async ({ orderId, params }) => {
    return (await fetchWrapper.get(`${baseUrl}/order/${orderId}/order-line-item/getAll?${params || ""}`))
  }
);

export const deleteOrderLineItem = createAsyncThunk(
  `${sliceName}/deleteOrderLineItem`,
  async (body) => await fetchWrapper.post(`${baseUrl}/order/item/delete`, body)
);

export const getOrderLineItemeMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getStoreMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/order-line-item/getMetadata?versioning=true&${params || ""}`)
);

export const getShippingDetailsByOrderlineItmeId = createAsyncThunk(
  `${sliceName}/getShippingDetailsByOrderlineItmeId`,
  async (orderlineItmeId) => await fetchWrapper.get(`${baseUrl}/order-line-item/${orderlineItmeId}/shipping/get`)
);

export const getAllOrderLineItemRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getAllOrderLineItemRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/order/order-line-item/version-history/get/${id}?${params || ""}`)
);


export const getCollectionById = createAsyncThunk(
  `${sliceName}/getCollectionById`,
  //async (id) => await fetchWrapper.get(`${baseUrl}/set/get/${id}`)
  async (id) =>
    await fetchWrapper.get(
      `${baseUrl}/collection/get/${id}?filter[include][][relation]=collectionPropertyValues`
    )
);
export const addProduct = createAsyncThunk(
  `${sliceName}/addProduct`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/order/item/add`, values);
    return res || res === "" ? res : null;
  }
);
export const getProductById = createAsyncThunk(
  `${sliceName}/getProductById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order/item/get/${id}`)
);
//Orders Internal State Data
/* table Meta Data */
export const getOrdersInternalStateMetaData = createAsyncThunk(
  `${sliceName}/getOrdersInternalStateMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order-internal-states/getMetadata`)
);

export const getOrdersInternalStateRowData = createAsyncThunk(
  `${sliceName}/getOrdersInternalStateMetaData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/order-internal-states/getAll?${params || ""}`)
);

export const deleteOrderInternalState = createAsyncThunk(
  `${sliceName}/deleteOrderInternalState`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/order-internal-states/delete/${id}`, { id })
);

export const getOrderInternalStateById = createAsyncThunk(
  `${sliceName}/getOrderInternalStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order-internal-states/get/${id}`)
);

export const addOrderInternalStates = createAsyncThunk(
  `${sliceName}/addOrderInternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/order-internal-states/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editOrderLineItem = createAsyncThunk(
  `${sliceName}/editOrderLineItem`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order/order-line-item/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const editOrderLineItemById = createAsyncThunk(
  `${sliceName}/editOrderLineItemById`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order/item/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const editOrderInternalStates = createAsyncThunk(
  `${sliceName}/editOrderInternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-internal-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);
//Orders Line Item External State Data
/* table Meta Data */
export const getOrdersLineItemExternalStateMetaData = createAsyncThunk(
  `${sliceName}/getOrdersLineItemExternalStateMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order-line-item-external-states/getMetadata`)
);

export const getOrdersLineItemExternalStateRowData = createAsyncThunk(
  `${sliceName}/getOrdersLineItemExternalStateMetaData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/order-line-item-external-states/getAll?${params || ""}`)
);

export const deleteOrdersLineItemExternalState = createAsyncThunk(
  `${sliceName}/deleteOrdersLineItemExternalState`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/order-line-item-external-states/delete/${id}`, { id })
);

export const getOrderLineItemExternalStateById = createAsyncThunk(
  `${sliceName}/getOrderLineItemExternalStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order-line-item-external-states/get/${id}`)
);

export const addOrderLineItemExternalStates = createAsyncThunk(
  `${sliceName}/addOrderLineItemExternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/order-line-item-external-states/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editOrderLineItemExternalStates = createAsyncThunk(
  `${sliceName}/editOrderLineItemExternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-line-item-external-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getOrderLineItemById = createAsyncThunk(
  `${sliceName}/getOrderLineItemById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order/order-line-item/get/${id}`)
);

//Orders Line Item Internal State Data
/* table Meta Data */
export const getOrdersLineItemInternalStateMetaData = createAsyncThunk(
  `${sliceName}/getOrdersLineItemInternalStateMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order-line-item-internal-states/getMetadata`)
);

export const getOrdersLineItemInternalStateRowData = createAsyncThunk(
  `${sliceName}/getOrdersLineItemInternalStateMetaData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/order-line-item-internal-states/getAll?${params || ""}`)
);

export const deleteOrdersLineItemInternalState = createAsyncThunk(
  `${sliceName}/deleteOrdersLineItemInternalState`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/order-line-item-internal-states/delete/${id}`, { id })
);

export const getOrderLineItemInternalStateById = createAsyncThunk(
  `${sliceName}/getOrderLineItemInternalStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order-line-item-internal-states/get/${id}`)
);

export const addOrderLineItemInternalStates = createAsyncThunk(
  `${sliceName}/addOrderLineItemInternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/order-line-item-internal-states/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editOrderLineItemInternalStates = createAsyncThunk(
  `${sliceName}/editOrderLineItemInternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-line-item-internal-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);


// BOM

export const getBOMrowData = createAsyncThunk(
  `${sliceName}/getBOMrowData`,
  async ({ orderlineItemId, translateId }) => await fetchWrapper.get(`${baseUrl}/order/item/${orderlineItemId}/product/get/${translateId}`)
);

export const getStockTagRowData = createAsyncThunk(
  `${sliceName}/getStockTagRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stock-tag/getAll?${params || ""}`)
);

export const getStockTagList = createAsyncThunk(
  `${sliceName}/getStockTagList`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stock-tag/dropdown/getAll?${params || ""}`)
);


export const productPricingWithIncentive = createAsyncThunk(
  `${sliceName}/productPricingWithIncentive`,
  async (fromData) => await fetchWrapper.post(`${baseUrl}/product/product-pricing-with-incentive`, fromData)
);


export const getOrderLineItemIncentiveMetadata = createAsyncThunk(
  `${sliceName}/getOrderLineItemIncentiveMetadata`,
  async () => await fetchWrapper.get(`${baseUrl}/order-line-item/incentive/getMetadata`)
);

export const getOrderLineItemIncentiveRowData = createAsyncThunk(
  `${sliceName}/getOrderLineItemIncentiveRowData`,
  async (data) => await fetchWrapper.post(`${baseUrl}/order-line-item/incentive/get`, data)
);

// Incentive State
export const getIncentiveStateMetaData = createAsyncThunk(
  `${sliceName}/getIncentiveStateMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/incentive-internal-states/getMetadata`)
);

export const getIncentiveStateRowData = createAsyncThunk(
  `${sliceName}/getIncentiveStateRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/incentive-internal-states/getAll?${params || ""}`)
);

export const getIncentiveStateById = createAsyncThunk(
  `${sliceName}/getInternalStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/incentive-internal-states/get/${id}`)
);

export const addIncentiveStates = createAsyncThunk(
  `${sliceName}/addIncentiveStates`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/incentive-internal-states/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editIncentiveStates = createAsyncThunk(
  `${sliceName}/editIncentiveStates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/incentive-internal-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const deleteIncentiveState = createAsyncThunk(
  `${sliceName}/deleteIncentiveState`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/incentive-internal-states/delete/${id}`, { id })
);

export const updateIncentiveAction = createAsyncThunk(
  `${sliceName}/updateIncentiveAction`,
  async ({ values, id }) => {
    const res = await fetchWrapper.post(`${baseUrl}/incentive/change-state/${id}`, values);
    return res || res === "" ? res : null;
  }
);

export const addGiftVoucherWrapper = createAsyncThunk(
  `${sliceName}/addGiftVoucherWrapper`,
  async (values) => await fetchWrapper.post(`${baseUrl}/gift-vouchers/add`, values)
);

export const getGiftVoucherRowData = createAsyncThunk(
  `${sliceName}/getGiftVoucherRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/gift-vouchers/getAll${params ? '?' + params : ''}`)
);

export const getGiftVoucherMetaData = createAsyncThunk(
  `${sliceName}/getGiftVoucherMetaData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/gift-vouchers/getMetadata${params ? '?' + params : ''}`)
);

export const getGiftVoucherById = createAsyncThunk(
  `${sliceName}/getGiftVoucherById`,
  async (apiParams) => {
    let id = apiParams?.id
    if (!id) {
      id = apiParams
    }
    return await await fetchWrapper.get(`${baseUrl}/gift-vouchers/get/${id}`)
  }
);

export const editGiftVoucher = createAsyncThunk(
  `${sliceName}/editGiftVoucher`,
  async (values) => await fetchWrapper.post(`${baseUrl}/gift-vouchers/update/${values.id}`, values)
);

export const getGiftVoucherVersionRowData = createAsyncThunk(
  `${sliceName}/getGiftVoucherVersionRowData`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/gift-vouchers/version-history/get/${id}${params ? '?' + params : ''}`)
);

export const duplicateGiftVoucher = createAsyncThunk(
  `${sliceName}/duplicateGiftVoucher`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/gift-vouchers/duplicate/${id}`)
);

export const deleteGiftVoucher = createAsyncThunk(
  `${sliceName}/deleteGiftVoucher`,
  async ({ id, deletePermanent }) => await fetchWrapper.post(`${baseUrl}/gift-vouchers/delete${deletePermanent ? '-permanent' : ''}/${id}`, { id })
);

export const getOrederActionsList = createAsyncThunk(
  `${sliceName}/getOrederActionsList`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order/${id}/action/getAll`)
);

export const applyOrderCouponCode = createAsyncThunk(
  `${sliceName}/applyOrderCouponCode`,
  async ({ objectId, code }) => await fetchWrapper.post(`${baseUrl}/order/${objectId}/coupon/${code}/apply`)
);

export const getOrderPriceDetails = createAsyncThunk(
  `${sliceName}/getOrderPriceDetails`,
  async (id) => await fetchWrapper.get(`${baseUrl}/order/price/details/get?orderId=${id}`)
);

export const addAddressDocuments = createAsyncThunk(
  `${sliceName}/addAddressDocuments`,
  async ({ values, addressId }) => await fetchWrapper.post(`${baseUrl}/address-documents/add/${addressId}`, values, 'multipart/form-data')
);

export const getOrderNotesMetadata = createAsyncThunk(
  `${sliceName}/getOrderNotesMetadata`,
  async () => await fetchWrapper.get(`${baseUrl}/order-notes/getMetadata`)
);

export const getOrderSpecialInstructionsMetadata = createAsyncThunk(
  `${sliceName}/getOrderSpecialInstructionsMetadata`,
  async () => await fetchWrapper.get(`${baseUrl}/order-special-instructions/getMetadata`)
);

export const getOrderAddressDocumentsMetadata = createAsyncThunk(
  `${sliceName}/getOrderAddressDocumentsMetadata`,
  async () => await fetchWrapper.get(`${baseUrl}/address-documents/getMetadata`)
);

export const updateOrderAction = createAsyncThunk(
  `${sliceName}/updateOrderAction`,
  async ({ orderId, action }) => {
    const res = await fetchWrapper.post(`${baseUrl}/order/${orderId}/${action}`);
    return res || res === "" ? res : null;
  }
);

export const removeOrderCoupon = createAsyncThunk(
  `${sliceName}/removeOrderCoupon`,
  async ({ objectId, code }) => {
    const res = await fetchWrapper.post(`${baseUrl}/order/${objectId}/coupon/${code}/remove`);
    return res || res === "" ? res : null;
  }
);

export const getAddressDocuments = createAsyncThunk(
  `${sliceName}/getAddressDocuments`,
  async (id) => await fetchWrapper.get(`${baseUrl}/address/get/${id}`)
);

export const getOrederLineItemActionsList = createAsyncThunk(
  `${sliceName}/getOrederLineItemActionsList`,
  async (objectId) => await fetchWrapper.get(`${baseUrl}/order/item/${objectId}/action/getAll`)
);

export const updateOrderLineItemAction = createAsyncThunk(
  `${sliceName}/updateOrderLineItemAction`,
  async ({ values, action }) => {
    const res = await fetchWrapper.post(`${baseUrl}/order/item/${action}`, values);
    return res || res === "" ? res : null;
  }
);

export const getBespokeMediaMetadata = createAsyncThunk(
  `${sliceName}/getBespokeMediaMetadata`,
  async () => await fetchWrapper.get(`${baseUrl}/order-line-item/bespoke-media/getMetadata`)
);

export const getOrderLineItemMediaMetadata = createAsyncThunk(
  `${sliceName}/getOrderLineItemMediaMetadata`,
  async () => await fetchWrapper.get(`${baseUrl}/order-line-item/media/getMetadata`)
);


export const addOrderLineItemMediaAttachment = createAsyncThunk(
  `${sliceName}/addOrderLineItemMediaAttachment`,
  async ({ values, id }) => await fetchWrapper.post(`${baseUrl}/order-line-item/${id}/media/attachment/add`, values, 'multipart/form-data')
);

export const addBespokeMediaAttachment = createAsyncThunk(
  `${sliceName}/addBespokeMediaAttachment`,
  async ({ values, id }) => await fetchWrapper.post(`${baseUrl}/order-line-item/${id}/bespoke-media/attachment/add`, values, 'multipart/form-data')
);

export const getOrderLineItemServiceInternalStates = createAsyncThunk(
  `${sliceName}/getOrderLineItemServiceInternalStates`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order-line-item-service-internal-states/getAll`)
);

export const getFraudScoreMetaData = createAsyncThunk(
  `${sliceName}/getFraudScoreMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order/list/byFraudScore/getMetadata`)
);
export const getFraudScoreRowData = createAsyncThunk(
  `${sliceName}/getFraudScoreRowData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order/list/byFraudScore?pageSize=10&pageIndex=0`)
);

export const getAdditionalPriceMetaData = createAsyncThunk(
  `${sliceName}/getAdditionalPriceMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order-line-item-additional-price-maps/getMetadata`)
);

export const getOrderLineItemInventoryTagMetaData = createAsyncThunk(
  `${sliceName}/getOrderLineItemInventoryTagMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order-line-item-inventory-tag-maps/getMetadata`)
);

export const getOrderLineItemBenchmarkInventoryTagMetaData = createAsyncThunk(
  `${sliceName}/getOrderLineItemBenchmarkInventoryTagMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order-line-item-benchmark-inventory-tag-stone-maps/getMetadata`)
);

export const deleteAddressDocument = createAsyncThunk(
  `${sliceName}/deleteAddressDocument`,
  async (addressDocumentId) => await fetchWrapper.post(`${baseUrl}/address-documents/add/${addressDocumentId}/attachment/delete`)
);


export const deleteBespokeMediaAttachment = createAsyncThunk(
  `${sliceName}/deleteBespokeMediaAttachment`,
  async (orderLineItemBespokeMediaId) => await fetchWrapper.post(`${baseUrl}/order-line-item/${orderLineItemBespokeMediaId}/bespoke-media/attachment/delete`)
);

export const deleteOrderLineItemMedia = createAsyncThunk(
  `${sliceName}/deleteOrderLineItemMedia`,
  async (orderLineItemMediaId) => await fetchWrapper.post(`${baseUrl}/order-line-item/media/${orderLineItemMediaId}/attachment/delete`)
);

export const refundOrderPayment = createAsyncThunk(
  `${sliceName}/refundOrderPayment`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/order/payment/refund`, values);
    return res || res === "" ? res : null;
  }
);


export const validateOrderLineItemActionPolicy = createAsyncThunk(
  `${sliceName}/validateOrderLineItemActionPolicy`,
  async ({ objectId, orderLineItemId, action }) => {
    const res = await fetchWrapper.get(`${baseUrl}/order/${objectId}/item/${orderLineItemId}/policy/${action}/validate/getResult`);
    return res || res === "" ? res : null;
  }
);


export const getOrderLineItemAdditionalPrice = createAsyncThunk(
  `${sliceName}/getOrderLineItemAdditionalPrice`,
  async ({ orderLineItemId }) => await fetchWrapper.get(`${baseUrl}/order-line-item/get/${orderLineItemId}/orderLineItemAdditionalPrice`)
);

export const cancelReturnOrderItem = createAsyncThunk(
  `${sliceName}/cancelReturnOrderItem`,
  async ({ action, override, values }) => {
    const res = await fetchWrapper.post(`${baseUrl}/order/item/${action}?override=${override}`, values);
    return res || res === "" ? res : null;
  }
);

export const getCustomerCreditNotes = createAsyncThunk(
  `${sliceName}/getCustomerCreditNotes`,
  async ({ customerId }) => {
    const res = await fetchWrapper.get(`${baseUrl}/customers/${customerId}/credit-notes/get`);
    return res || res === "" ? res : null;
  }
);

export const getOrderLineItemPriceDetails = createAsyncThunk(
  `${sliceName}/getOrderLineItemPriceDetails`,
  async ({ orderLineItemId, orderId }) => await fetchWrapper.get(`${baseUrl}/order/price/details/get?orderLineItemId=${orderLineItemId}&orderId=${orderId}`)
);

export const checkV12PaymentStatus = createAsyncThunk(
  `${sliceName}/checkV12PaymentStatus`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/payments/v12Finance/checkApplicationStatus`, values);
    return res || res === "" ? res : null;
  }
);

export const requestV12Payment = createAsyncThunk(
  `${sliceName}/requestV12Payment`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/payments/v12Finance/requestPayment`, values);
    return res || res === "" ? res : null;
  }
);

export const requestV12PartialRefund = createAsyncThunk(
  `${sliceName}/requestV12PartialRefund`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/payments/v12Finance/requestPartialRefund`, values);
    return res || res === "" ? res : null;
  }
);

export const cancelV12Application = createAsyncThunk(
  `${sliceName}/cancelV12Application`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/payments/v12Finance/cancelApplication`, values);
    return res || res === "" ? res : null;
  }
);

export const getOrderLineItemPaymentDetails = createAsyncThunk(
  `${sliceName}/getOrderLineItemPaymentDetails`,
  async ({ orderId, orderLineItemId }) =>
    await fetchWrapper.get(`${baseUrl}/order/${orderId}/item/${orderLineItemId}/payment/getAll`)
);

export const getRepairRequestRowDataDetails = createAsyncThunk(
  `${sliceName}/getRepairRequestRowDataDetails`,
  async ({ params, orderId }) =>
    await fetchWrapper.get(`${baseUrl}/order/${orderId}/linked/repair-order/getAll?${params || ""}`)
);